import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

import { makeQueryablePromise } from '@/inc/utils'
import { Chrome, QueryablePromise, Resource } from '@/inc/types'

const { EPIC_ENV } = process.env
const isProd = EPIC_ENV === 'production' || EPIC_ENV === 'pre'

type ApiResponse = Chrome | Resource
interface CacheData {
  response: QueryablePromise<AxiosResponse<ApiResponse>>
  created: number
}

/**
 * Cache HTTP responses
 */
const expires = isProd ? 1000 * 60 * 60 : 100

export const data: Map<string, CacheData> = new Map()
export const fetch = (
  path: string,
  url: string,
  config?: AxiosRequestConfig
) => {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  if (!data.has(path) || data.get(path)!.created < Date.now() - expires) {
    data.set(path, {
      response: makeQueryablePromise(axios.get(url, config)),
      created: Date.now(),
    })
  }

  return data.get(path)!.response
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
}
