



















































































































































































































































































































import { defineComponent, onUnmounted } from '@vue/composition-api'
import { ContactInfos } from '@/inc/types'
import { useGetters, useMutations } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'GContactInfos',
  props: {
    content: {
      type: Object as () => ContactInfos,
      default: {} as ContactInfos,
    },
  },

  setup(_props, ctx) {
    const goBack = initialUrl => {
      const { url } = useGetters('backTo', ['url'])
      const router = ctx.root.$router

      // eslint-disable-next-line no-unused-expressions
      url.value ? router.push(url.value) : router.push(initialUrl)
    }

    onUnmounted(() => {
      const { BACKTO_REMOVE: setBackTo } = useMutations('backTo', [
        'BACKTO_REMOVE',
      ])

      setBackTo(null)
    })

    return {
      goBack,
    }
  },
})
