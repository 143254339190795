import { Language } from '@/inc/types'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr', 'en'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {
  news: ['actus', 'news'],
  nested: ['imbrique', 'nested'],
  nestedFoo: ['foo', 'fooooo'],
  nestedBar: ['bar', 'baaaar'],
} as Record<string, string | string[]>
export const endpointDefault = 'pages'

// Options
export const isMultisite = false
export const useCookiebar = true
export const favicon = {
  tabColor: '#317af9',
  tileColor: '#317af9',
  themeColor: '#ffffff',
}
export const svgSpritePath = 'src/inc/svg/sprite.svg'

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-WZ2SB6H',
  staging: 'GTM-MMMK5CZ',
}

// API URLs
export const getApiUrl = (site?: string) => {
  const defaultSite = isMultisite ? '/shared' : ''

  return `${process.env.VUE_APP_API_DOMAIN}${site ? `/${site}` : defaultSite}${
    process.env.VUE_APP_API_PATH
  }`
}
export const getThumborUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

export const getEnvUrl = process.env.VUE_APP_API_DOMAIN
