import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

import { langDefault, langAvailable } from '@/inc/app.config'
import VRoot from '@/views/Root.vue'
// Import { Language } from '@/inc/types'

// DEV: error on "rebuild", see NOTES.md
// const loadView = (view: string) => () =>
//   import(
//     /* webpackChunkName: 'v-[request]' */ `@/views/${view.toLowerCase()}/${view}.vue`
//   )

// Format segments to (a|b)
// const formatSegment = (key: string): string =>
//   Array.isArray(segments[key])
//     ? `(${(segments[key] as string[]).join('|')})`
//     : (segments[key] as string)

let routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    // DEV not working.
    // component: loadView('Home'),
    component: () =>
      import(/* webpackChunkName: 'v-home' */ '@/views/Home.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
  // {
  //   // Redirection example (/fr/nested/ => /fr/nested/foo/)
  //   path: `/${formatSegment('nested')}/`,
  //   redirect: to => {
  //     const { fullPath, params } = to
  //     const { lang } = params as { lang: Language }
  //     const langIndex = langAvailable.indexOf(lang)

  //     // Return original path + default segment
  //     return `${fullPath}${segments.nestedFoo[langIndex]}/`
  //   },
  // },
  // {
  //   // Nested means that main view uses a <router-view>
  //   path: `/${formatSegment('nested')}/`,
  //   component: () =>
  //     import(/* webpackChunkName: 'v-nested' */ '@/views/Nested.vue'),
  //   children: [
  //     {
  //       // Cool, named param and dynamic segment
  //       path: `:sub${formatSegment('nestedFoo')}`,
  //       name: 'nested-foo',
  //       component: () =>
  //         import(
  //           /* webpackChunkName: 'v-nested-foo' */ '@/views/nested/Foo.vue'
  //         ),
  //       meta: {
  //         // Same key === no refresh
  //         key: 'nested',
  //         // Name your specific endpoint
  //         endpoint: 'nested',
  //         // Want to scroll to top?
  //         scrollTop: true,
  //         ssr: true,
  //       },
  //     },
  //     {
  //       path: `:sub${formatSegment('nestedBar')}`,
  //       name: 'nested-bar',
  //       component: () =>
  //         import(
  //           /* webpackChunkName: 'v-nested-foo' */ '@/views/nested/Bar.vue'
  //         ),
  //       meta: {
  //         key: 'nested',
  //         endpoint: 'nested',
  //         ssr: true,
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: `/${formatSegment('news')}`,
  //   name: 'news-archive',
  //   component: () =>
  //     import(/* webpackChunkName: 'v-news' */ '@/views/News.vue'),
  //   meta: {
  //     endpoint: 'news',
  //     ssr: true,
  //   },
  // },
  {
    path: '/profile/:slug*',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: 'v-profile' */ '@/views/Profile.vue'),
    meta: {
      site: '',
      endpoint: 'profile',
      ssr: true,
    },
  },
  {
    path: '/equipe/',
    name: 'team',
    component: () =>
      import(/* webpackChunkName: 'v-profile' */ '@/views/Profiles.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
  {
    path: '/equipe/:slug*',
    name: 'team',
    component: () =>
      import(/* webpackChunkName: 'v-profile' */ '@/views/Profiles.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
  {
    path: '/blog/',
    name: 'news-archive',
    component: () =>
      import(/* webpackChunkName: 'v-profile' */ '@/views/Blog.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
  {
    path: '/blog/:slug*',
    name: 'news-archive',
    component: () =>
      import(/* webpackChunkName: 'v-profile' */ '@/views/Blog.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
  {
    path: '/news/:slug*',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: 'v-news' */ '@/views/DefaultTemplate.vue'),
    meta: {
      site: '',
      endpoint: 'news',
      ssr: true,
    },
  },
  {
    path: '/project/:slug*',
    name: 'project',
    component: () =>
      import(/* webpackChunkName: 'v-project' */ '@/views/DefaultTemplate.vue'),
    meta: {
      site: '',
      endpoint: 'project',
      ssr: true,
    },
  },
  {
    path: '/:slug/:sub?',
    name: 'page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
  {
    path: '/*',
    name: 'all',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      site: '',
      ssr: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})/`,
      component: VRoot as VueConstructor<Vue>,
      children: routes.map(r => {
        // Remove leading slash
        r.path = r.path.replace(/^\//, '')

        return r
      }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
  ]
}

export { routes }
